@use './styles/varriables.scss';
@use './styles/root.scss';
@use './styles/atomic.scss';

@use './styles/custom.scss';

@use './styles/features/header.scss';
@use './styles/features/footer.scss';
@use './styles/features/login.scss';
@use './styles/features/question-flow.scss';
@use './styles/features/adminChanges.scss';
@use './styles/features/insurance.scss';
@use './styles/features/chat.scss';
@use './styles/features/claims.scss';
@use './styles/features/pet.scss';
@use './styles/features/loader.scss';


@import './claimsportal.scss';
@import "react-quill/dist/quill.snow.css";

.first-last-name {
  position: relative;
  width: 1100px;
  color: var(--b-color-text-primary-1);
  font-family: var(--b-font-family-primary-1);
  font-size: var(--b-font-size-xl);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 10vh 0 7vh;

  @media screen and (orientation: landscape) and (max-width: 1200px) {
    width: 100%;
    margin: 10vh 0 15vh 0;
  }

  @media screen and (orientation: portrait) and (max-width: 1200px) {
    width: 100%;
    margin: 0 0 3.5vh 0;
  }
}

.form-body {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 50px 0 0 0;
  row-gap: 30px;
  column-gap: 40px;
  margin: 0 0 50px 0;

  @media screen and (max-width: 1200px) {
    width: 96%;
  }

  &__group {
    display: flex;
    flex-direction: column;
    width: 530px;
    gap: 14px;

    @media screen and (max-width: 1200px) {
      width: 100%;
    }

    @media screen and (max-width: 600px) {
      width: 100%;
      gap: 9px;
    }
  }

  &__group-title {
    color: var(--b-color-main-1);
    leading-trim: both;
    text-edge: cap;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: var(--b-font-family-primary-1);
    font-size: var(--b-font-size-xl);
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;

    @media screen and (max-width: 1200px) {
      font-size: 18px;
    }

    @media screen and (max-width: 600px) {
      font-size: 16px;
    }
  }

  &__item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    >span {
      color: var(--b-color-text-primary-1);
      leading-trim: both;
      text-edge: cap;
      font-variant-numeric: lining-nums proportional-nums;
      font-family: var(--b-font-family-primary-1);
      font-size: var(--b-font-size-xl);
      font-style: normal;
      font-weight: bold;
      line-height: 24px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @media screen and (max-width: 1200px) {
        font-size: 18px;
      }

      @media screen and (max-width: 600px) {
        font-size: 16px;
      }
    }

    >input {
      width: 350px;
      height: 35px;
      display: flex;
      padding: 10px 15px;
      align-items: center;
      border-radius: 140px;
      border: 1px dashed var(--b-shadow-main);
      background: var(--b-color-bg-main-2);
      color: var(--b-shadow-main);
      font-size: 20px;
      font-family: var(--b-font-family-primary-1);
      font-weight: 500;
      font-style: normal;
      line-height: 24px;
      leading-trim: both;
      text-edge: cap;
      font-variant-numeric: lining-nums proportional-nums;

      @media screen and (max-width: 1200px) {
        width: 60%;
        font-size: 18px;
        height: 28px;
      }

      @media screen and (max-width: 600px) {
        width: 60%;
        font-size: 16px;
        height: 20px;
      }
    }

    &__readonly {
      width: 350px;
      padding: 0 0 0 15px;
      font-weight: 500 !important;

      @media screen and (max-width: 1200px) {
        width: 60%;
      }
    }
  }

  &__item-input {
    width: 350px;
    display: flex;
    padding: 10px 15px;
    align-items: center;
    border-radius: 140px;
    border: 1px dashed var(--b-shadow-main);
    background: var(--b-color-bg-main-2);
    color: var(--b-shadow-main);
    font-size: 20px;
    font-family: var(--b-font-family-primary-1);
    font-weight: 600;
    font-style: normal;
    line-height: 24px;
    leading-trim: both;
    text-edge: cap;
    font-variant-numeric: lining-nums proportional-nums;
  }

  &__group-button {
    width: 60%;
    display: flex;
    flex-direction: row;
    gap: 5px;

    @media screen and (min-width: 1200px) {
      width: 350px;
      gap: 10px;
    }
  }

  &__item-button {
    width: 100%;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 140px;
    border: 1px dashed var(--b-shadow-main);
    background: var(--b-color-bg-main-2);
    color: var(--b-shadow-main);
    font-size: 16px;
    font-family: var(--b-font-family-primary-1);
    font-weight: 600;
    font-style: normal;
    line-height: 24px;
    leading-trim: both;
    text-edge: cap;
    font-variant-numeric: lining-nums proportional-nums;

    @media screen and (min-width: 600px) {
      font-size: 18px;
      height: 28px;
      padding: 10px;
    }

    @media screen and (min-width: 1200px) {
      font-size: 20px;
      height: 35px;
      padding: 10px;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;

    @media screen and (min-width: 900px) {
      flex-direction: row;
    }
  }
}

.customer-title {
  width: 60%;

  @media screen and (min-width: 1200px) {
    width: 350px;
  }
}

.cust-title {
  &__control {
    height: 20px;
    font-size: 16px;

    @media screen and (min-width: 600px) {
      font-size: 18px;
      height: 28px;
    }

    @media screen and (min-width: 1200px) {
      height: 35px;
      font-size: 20px;
    }
  }

  &__single-value {
    font-size: 16px;

    @media screen and (min-width: 600px) {
      font-size: 18px;
    }

    @media screen and (min-width: 1200px) {
      font-size: 20px;
    }
  }
}

.enter_separator {
  width: 1px;
  height: 270px;
  border: 1px dashed var(--b-shadow-main);
  opacity: 0.5;
}

.change-mode {
  z-index: 1000;
  position: fixed;
  bottom: 50px;
  right: 100px;
}

.chat-bubble {
  z-index: 1000;
  position: fixed;
  bottom: 30px;
  left: 30px;
  cursor: pointer;
  transition: 0.3s;

  @media screen and (min-width: 600px) {
    bottom: 50px;
    left: 50px;
  }
}

.chat-bubble:hover {
  scale: 1.35;
  transition: 0.3s;
}

.action-content {
  display: flex;
  align-items: center;
  gap: 10px;
}

.navbar-brand {
  display: flex;
  column-gap: 15px;
  font-size: 1.25rem;
  line-height: inherit;
  margin-right: 1rem;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
  white-space: nowrap;
}

.mobile-menu-wrapper {
  position: fixed;
  top: 128px;
  left: 0;
  right: 0;
  height: 100vh;
  z-index: 1000;
  display: none;
  transform: translateX(100vw);
  transition: all 1s;

  @media (max-width: 1199px) {
    display: block;
  }

  &.show {
    transform: translateX(0);
  }

  &.hide {
    transform: translateX(100vw);
  }
}

.container-vendor {
  background: none;
  position: fixed;
  z-index: 111;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  animation: smooth 2s;

  @keyframes smooth {
    0% {
      background: var(--b-color-text-primary-2);
    }

    100% {
      background: none;
    }
  }
}

.mobile-navbar {
  background-color: var(--secondary-color);
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  padding-bottom: 20px;
  align-items: flex-end;

  .navbar-nav {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-end;
  }

  .nav-item {
    display: flex;
    justify-content: flex-end;
  }
}

.header-spacer {
  position: absolute;
  height: calc(100vh - 90px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--b-color-bg-main-2);
  top: 90px;
  left: 0px;
  z-index: 10;
  box-shadow: 4px 3px 10px 0px var(--b-shadow-light);
  overflow-y: scroll;
  outline: none;
  scrollbar-width: none;
  /* Hide scrollbar for Firefox */
  -ms-overflow-style: none;
  /* Hide scrollbar for IE and Edge */
}

.header-spacer::-webkit-scrollbar {
  width: 0px;
  /* Hide scrollbar for Chrome, Safari, and Opera */
}

.menu-item:nth-child(odd) {
  background-color: var(--b-color-bg-main-2) !important;
}

a.menu-item:nth-child(odd) {
  &>div {
    background-color: var(--b-color-bg-main-2) !important;
  }
}

.header-blur {
  position: fixed;
  right: 0;
  bottom: 0;
  top: 90px;
  left: 0;
  background: var(--b-color-bg-additional-5);
  z-index: -1;
}

.settings-page {
  padding: 2rem 0;
}

.main-logo-policy {
  width: 210px;
  position: absolute;
  left: 7%;
  top: -97px;
  z-index: 5;

  @media screen and (max-width: 940px) {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    top: 23px;
  }

  &__mobile {
    @media screen and (max-width: 1200px) {
      width: 130px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      top: -3px;
    }

    @media screen and (max-width: 600px) {
      width: 100px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      top: 9px;
    }

    @media screen and (max-width: 350px) {
      width: 64px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      top: 26px;
    }
  }
}

.main-logo-account {
  width: 180px;
  position: absolute;
  left: 50%;
  top: -50px;
  z-index: 5;
  transform: translateX(-50%);

  @media screen and (max-width: 1200px) {
    top: -32px;
    width: 160px;
  }

  @media screen and (orientation: landscape) and (max-width: 1200px) {
    top: -35px;
  }

  @media screen and (orientation: landscape) and (max-width: 900px) {
    top: -32px;
  }

  @media screen and (orientation: landscape) and (max-width: 800px) {
    top: -32px;
  }

  @media screen and (max-width: 600px) {
    width: 123px;
    top: -22px;
  }

  @media screen and (max-width: 400px) {
    top: -22px;
  }
}

.main-logo-login {
  width: 85px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -28px;
  z-index: 5;

  &.de {
    left: 500px;

    @media screen and (max-width: 1200px) {
      left: 50%;
      transform: translateX(138px);
      top: -25px;
      width: 75px;
    }

    @media screen and (max-width: 600px) {
      transform: translateX(102px);
      top: -20px;
      width: 60px;
      display: none;
    }

    @media screen and (max-width: 425px) {
      transform: translateX(-106px);
      top: 12px;
      width: 60px;
      display: none;
    }

    @media screen and (max-width: 324px) {
      transform: translateX(-14px);
      top: 12px;
      width: 60px;
      display: none;
    }
  }

  &.en {
    left: 350px;

    @media screen and (max-width: 1200px) {
      left: 50%;
      transform: translateX(77px);
      top: -25px;
      width: 75px;
    }

    @media screen and (max-width: 600px) {
      transform: translateX(56px);
      top: -20px;
      width: 60px;
      display: none;
    }
  }
}

.main-logo-chat {
  width: 210px;
  position: absolute;
  left: 10%;
  top: 15px;
  z-index: 5;
}

.main-logo-modal-table {
  width: 140px;
  position: absolute;
  right: 50px;
  top: -62px;
  z-index: 5;
}

.account-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: clip;
}


.account {
  background-color: var(--b-color-text-primary-2);

  .form {
    &__top-label {
      margin-top: 2em;
      margin-bottom: 1rem;
      display: inline-block;
    }
  }

  &__form {
    &__top {
      margin-top: 1rem;
    }
  }
}

.form-group {
  margin-bottom: 1rem;
}

.form-contrel-plaintext {
  background-color: initial;
  border: solid transparent;
  border-width: 1px 0;
  color: var(--b-color-text-primary-1);
  display: block;
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 0;
  padding: 0.375rem 0;
  width: 100%;
}



.empty-icon {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 5vh 0 0 0;
  position: relative;
}

.empty-text {
  color: var(--b-color-text-highlight);
  display: flex;
  font-weight: 700;
  justify-content: center;
  padding: 40px 48px;
}

.contact-back {
  margin-left: -20px;
}

.contact {
  &__question {
    font-size: 18px;
    line-height: 1.56;
    color: var(--b-color-bg-additional-2);
  }

  &__area {
    min-height: 250px !important;
  }

  &__wrapper {
    background-color: var(--b-color-text-primary-2);
  }
}

.faq {
  &__title {
    text-align: center;
    padding-bottom: 1em;
  }
}

.transaction-h {
  &__header {
    @media screen and (min-width: 800px) {
      padding-left: 48px;
      padding-right: 48px;
    }

    display: flex;
    justify-content: space-between;
    padding-bottom: 4.5rem;
  }

  &__title {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
  }

  &__customer-id {
    display: flex;
    font-size: 16px;
    line-height: 16px;
  }

  &__customer-value {
    font-weight: 700;
    margin-left: 5px;
  }
}

.contact-c {
  &__row {
    margin-top: 50px;
  }

  &__label {
    font-weight: bold;
  }

  &__dates {
    margin: 1em 0px;

    label {
      padding-right: 10px;
      width: 75px;
      margin-bottom: 0.5rem;
    }

    input {
      min-height: 2.3em;
    }
  }
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;

  &>[class*="col"] {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.flow-variables {
  color: var(--b-color-text-primary-2);
  margin-top: 40px;

  &__title {
    font-size: 18px;
  }
}

.table_wrapper {
  display: flex;
  flex-direction: column;
  margin: 40px 0;
  overflow: auto;
}

.custom-arrow {
  width: 42px;
  height: 42px;
  background: var(--b-color-main-2);
  box-shadow: 2px 2px 20px var(-b-color-main-2);
  border-radius: 50%;
  position: absolute !important;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    width: 32px;
    height: 32px;
  }

  div {
    width: inherit;
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-next {
    right: -54px;
    top: 35%;

    @media (max-width: 1024px) {
      right: 13px;
      top: 43%;
      z-index: 3;
    }

    @media (max-width: 740px) {
      right: 35%;
      top: 105%;
    }

    @media (max-width: 360px) {
      right: 20%;
      top: 105%;
    }
  }

  &-prev {
    left: -54px;
    top: 35%;

    @media (max-width: 1024px) {
      left: 13px;
      top: 43%;
      z-index: 3;
    }

    @media (max-width: 740px) {
      left: 35%;
      top: 105%;
    }

    @media (max-width: 360px) {
      left: 20%;
      top: 105%;
    }
  }

  &:hover {
    background: var(--b-color-main-2);
    box-shadow: 2px 2px 20px var(-b-color-main-2);
    cursor: pointer;
  }
}

.full-width {
  @media screen and (max-width: 600px) {
    width: 100%;

    .custom-daterange {
      width: 100%;
    }
  }
}

.custom-daterange {
  .rmdp-container {
    @media screen and (max-width: 600px) {
      // height: 38px !important;
      width: 100%;
    }
  }

  .rmdp-input {
    height: 47px;
    width: 280px;
    padding: 0 20px 0 10px;
    border: 2px solid var(--b-color-main-1);
    border-radius: 12px;
    color: var(--b-color-main-1);
    font-variant-numeric: lining-nums proportional-nums;
    font-family: var(--b-font-family-primary-1);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    background-color: var(--b-color-bg-main-2);

    @media screen and (max-width: 600px) {
      height: 38px;
      width: 100%;
      font-size: 16px;
    }
  }

  .rmdp-input:focus {
    border: 2px solid var(--b-color-main-1);
    box-shadow: 0 0 2px var(--b-color-main-1);
    outline: none !important;
  }

  .rmdp-week-day {
    font-family: var(--b-font-family-primary-1);
    font-weight: 600;
  }

  .rmdp-header-values {
    font-family: var(--b-font-family-primary-1);
    font-size: 16px;
    font-variant-numeric: lining-nums proportional-nums;
    font-weight: 600;
  }

  .rmdp-day {
    font-family: var(--b-font-family-primary-1);
    font-variant-numeric: lining-nums proportional-nums;
    font-weight: 600;
  }

  .rmdp-day.rmdp-today span {
    background-color: var(--b-color-additional-1);
  }

  .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
    background-color: var(--b-color-main-2);
    color: var(--b-color-text-primary-2);
  }

  .rmdp-week-day {
    color: var(--b-color-main-1);
  }

  .rmdp-arrow {
    border: solid var(--b-color-main-1);

    border-width: 0 2px 2px 0;
  }

  .rmdp-arrow-container:hover {
    background-color: var(--b-color-main-1);
    box-shadow: 0 0 3px var(--b-color-bg-additional-2);
  }

  .rmdp-range {
    background-color: var(--b-color-main-1);
    box-shadow: 0 0 3px var(--b-color-bg-additional-2);
    color: var(--b-color-text-primary-2);
  }

  .rmdp-input::placeholder {
    color: var(--b-color-main-1);
    font-size: 22px;
    line-height: 24px;

    @media screen and (max-width: 600px) {
      font-size: 16px;
    }
  }

  &__close {
    position: absolute;
    width: 11px;
    right: 8px;
    top: 19px;
    cursor: pointer;
    opacity: 0.5;

    @media screen and (max-width: 600px) {
      top: 15px;
    }
  }

  &__close:hover {
    opacity: 1;
  }
}

.modal_container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;

  &__main {
    position: relative;
    width: 700px;
    display: flex;
    flex-direction: column;
    background-color: var(--b-color-bg-main-1);
    border-radius: 44px;
    margin: 30px 0 30px 0;
    // min-height: 300px;

    @media screen and (max-width: 760px) {
      width: 335px !important;
    }
  }

  &__header {
    position: relative;
    background-image: var(--bgi-modal-head);
    background-size: contain;
    background-repeat: no-repeat;
    height: 160px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    color: var(--b-color-text-primary-2);
    font-family: var(--b-font-family-primary-1);
    font-size: 34px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    @media screen and (max-width: 760px) {
      background-image: var(--bgi-modal-head-mobile);
      background-size: 100% 100%;
      font-size: 24px;
      height: 110px;
    }

    &__close {
      position: absolute;
      top: 25px;
      right: 25px;
      cursor: pointer;

      @media screen and (max-width: 760px) {
        width: 20px;
        height: 20px;
        top: 15px;
        right: 20px;
      }
    }

    &__logo {
      margin: 20px 0 15px 0;
      width: 70px;
      height: 40px;

      @media screen and (max-width: 760px) {
        width: 45px;
        height: 26px;
        margin: 10px 0 10px 0;
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    color: var(--b-color-text-primary-1);
    font-family: var(--b-font-family-primary-1);
    font-size: var(--b-font-size-xl);
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    // max-height: 600px;
    // overflow: hidden;
    &>span {
      color: var(--b-color-main-1);
      font-variant-numeric: lining-nums proportional-nums;
    }

    @media screen and (max-width: 760px) {
      font-size: 14px;
      padding: 20px 25px 10px 25px;
    }

    a {
      color: var(--b-color-main-2);
    }

    &__text {
      max-width: 480px;
      text-align: center;
      margin: 0 0 20px 0;
      font-variant-numeric: lining-nums;

      &>span>span {
        color: var(--b-color-main-2);
      }
    }

    &__amount {
      color: var(--b-color-main-2);
      font-size: 32px;
      max-width: 540px;
      text-align: center;
      margin: 0 0 20px 0;
      font-variant-numeric: lining-nums;

      @media screen and (max-width: 760px) {
        font-size: 20px;
      }
    }

    &__error {
      color: var(--b-color-main-2);
      font-size: 18px;
      max-width: 540px;
      text-align: center;
      margin: 0 0 20px 0;
      font-variant-numeric: lining-nums;

      @media screen and (max-width: 760px) {
        font-size: 14px;
      }
    }

    &__inputs {
      margin: 20px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 15px;
      width: 100%;

      img.copy-to-clipboard {
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        right: 10px;
        cursor: pointer;
      }

      &.wide-children {
        &>div {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 5px;
        }

        &>div>div {
          width: 100%;
        }
      }

      @media screen and (max-width: 760px) {
        width: 100%;
      }
    }

    &__radio {
      width: 400px;
      border-bottom: 1px solid var(--b-color-text-primary-1);

      &>label {
        cursor: pointer;
      }

      // input:checked {
      //   accent-color: var(--b-color-main-1);
      // }

      input[type="radio"] {
        display: none;
        /* Hide the native radio input */
      }

      /* Custom radio button circle */
      label:before {
        content: '';
        display: inline-block;
        width: 20px;
        height: 20px;
        // border: 3px solid #ccc;
        border-radius: 50%;
        margin-right: 12px;
        background-color: #fff;
        transition: all 0.3s ease;
        box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.2);
      }

      label.checked:before {
        background-color: #a00000;
        border: 3px solid #fff;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
      }

      /* Circle when the radio button is checked */
      input[type="radio"]:checked+label:before {
        background-color: #a00000;
        border: 3px solid #fff;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
      }

      /* Optional: Add a subtle shadow for the checked state */
      // input[type="radio"]:checked+label:before {
      //   box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.2);
      // }

      @media screen and (max-width: 760px) {
        width: 100%;
      }
    }

    &__radio:last-child {
      border-bottom: none;
    }

    &__radio-check {
      width: 520px;
      border-bottom: 1px solid var(--b-color-text-primary-1);

      &>label {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        padding: 10px 0;

        &>input {
          display: none;
        }

        &>span {
          flex-shrink: 0;
          width: 28px;
          height: 28px;
          border: 3px solid var(--b-color-additional-1);
          border-radius: 50%;
          position: relative;
          transition: background-color 0.2s, border-color 0.2s;

          &>img {
            position: absolute;
            top: -5px;
            width: 30px;
            transition: height 0.3s ease-out, transform 0.3s ease-out;
          }
        }
      }

      @media screen and (max-width: 760px) {
        width: 100%;
      }
    }

    &__radio-check:last-child {
      border-bottom: none;
    }

    &__input {
      width: 540px;
      height: 40px;
      padding: 10px 30px;
      border-radius: 13px;
      border: 1px solid var(--b-color-bg-additional-5);
      background: var(--b-color-bg-main-2);
      box-shadow: 0px 0px 8px 0px var(--b-shadow-main);
      color: var(--b-color-text-primary-1);
      font-family: var(--b-font-family-primary-1);
      font-size: var(--b-font-size-xl);
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      font-variant-numeric: lining-nums proportional-nums;

      @media screen and (max-width: 760px) {
        width: 100%;
        height: 30px;
        font-size: 16px;
        padding: 5px 15px;
      }

      &.clipboard {
        padding: 10px 40px 10px 30px;

        @media screen and (max-width: 760px) {
          padding: 5px 40px 5px 15px;
        }
      }
    }

    &__input-opacity {
      @extend .modal_container__body__input;
      color: var(--b-color-bg-additional-2);
    }

    &__input::placeholder {
      font-size: var(--b-font-size-xl);
      color: var(--b-color-bg-additional-2);

      @media screen and (max-width: 760px) {
        font-size: 16px;
      }
    }

    &__input-file {
      @extend .modal_container__body__input;
      width: 100%;
      font-size: 18px;
      padding: 10px 40px 10px 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__row {
      display: flex;
      flex-direction: row;
      column-gap: 20px;
      width: 100%;

      @media screen and (max-width: 760px) {
        flex-direction: column;
        row-gap: 15px;
      }
    }

    &__row-modal {
      display: flex;
      flex-direction: row;
      column-gap: 40px;

      @media screen and (max-width: 760px) {
        column-gap: 16px;
      }
    }

    &__column-modal {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 40px;

      @media screen and (max-width: 760px) {
        gap: 20px;
      }

      &_full-width {
        width: 100%;
      }
    }

    &__buttons {
      margin: 40px 0;
      display: flex;
      flex-direction: column;
      row-gap: 15px;

      @media screen and (max-width: 760px) {
        margin: 30px 0;
      }
    }

    &__button {
      width: 270px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 13px;
      background: var(--b-color-main-1);
      box-shadow: 0px 0px 8px 0px var(--b-shadow-main);
      color: var(--b-color-bg-main-2);
      font-family: var(--b-font-family-primary-1);
      font-size: var(--b-font-size-xl);
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      cursor: pointer;
      border: 0;

      @media screen and (max-width: 760px) {
        width: 200px;
        font-size: 14px;
      }
    }

    &__button:hover {
      background-color: var(--b-color-additional-1);
    }

    &__button-modal {
      border: 0;
      width: 270px;
      padding: 10px 35px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 13px;
      background: var(--b-color-main-2);
      box-shadow: 0px 0px 8px 0px var(--b-shadow-main);
      color: var(--b-color-bg-main-2);
      font-family: var(--b-font-family-primary-1);
      font-size: var(--b-font-size-xl);
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      font-variant-numeric: lining-nums proportional-nums;
      cursor: pointer;

      &.wide {
        width: 100%;
        white-space: nowrap;
      }

      &.selected {
        background: var(--b-color-main-1);
      }

      &.disabled {
        pointer-events: none;
        background: var(--b-color-additional-1);
      }

      @media screen and (max-width: 760px) {
        width: 200px;
        font-size: 14px;
      }
    }

    &__info {
      display: flex;
      width: 440px;
      margin: 20px 0;
      padding: 25px 22px;
      flex-direction: column;
      align-items: center;
      text-align: center;
      border-radius: 13px;
      background: var(--b-color-main-1);
      box-shadow: 0px 0px 18px 0px var(--b-shadow-light);
      position: relative;
      color: var(--b-color-bg-main-2);
      font-family: var(--b-font-family-primary-1);
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      font-variant-numeric: lining-nums proportional-nums;

      & span {
        font-size: var(--b-font-size-xl);
        margin: 0 0 5px 0;
      }

      & img {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(-50%, -40%);
        width: 100px;
      }

      @media screen and (max-width: 760px) {
        width: 100%;
        font-size: 14px;
        font-weight: 500;
        padding: 15px;
        margin: 15px 25px;

        & span {
          font-size: 14px;
          font-weight: 700;
        }

        & img {
          width: 60px;
        }
      }
    }

    &__dropdowns {
      display: flex;
      flex-direction: row;
      margin: 20px 0;
      row-gap: 15px;
    }

    &__attach {
      position: relative;
      display: flex;
      align-items: center;
      gap: 15px;
      width: 100%;
    }

    &__uploader {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      margin: 30px 0;
      border: 3px dashed var(--b-color-main-2);
      border-radius: 20px;
      padding: 20px 40px;
      cursor: pointer;

      & span {
        color: var(--b-color-additional-1);
        text-align: center;
      }
    }

    &__table {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;

      @media screen and (min-width: 600px) {
        width: 70%;
      }
    }

    &__form {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
    }

    &__radio-btn {
      // border: 1px solid var(--b-color-text-primary-1);
      border-radius: 13px;
      height: 49px;
      width: 540px;
      padding: 10px 15px;
      box-shadow: 0px 0px 8px 0px var(--b-shadow-main);
      color: var(--b-color-text-primary-1);
      font-family: var(--b-font-family-primary-1);
      font-size: var(--b-font-size-l);
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      font-variant-numeric: lining-nums proportional-nums;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 15px;
      cursor: pointer;

      @media screen and (max-width: 760px) {
        width: 100%;
        height: 30px;
        font-size: 16px;
        padding: 5px 15px;
      }

      &.selected {
        background-color: var(--b-color-additional-1);
        color: var(--b-color-text-primary-2);
      }
    }

    &__radio-btn:hover {
      background-color: var(--b-color-bg-additional-6);
    }
  }

  &__footer {
    position: relative;
    width: 214px;
    height: 117px;
    bottom: -14px;
    left: 50%;
    transform: translateX(-50%);

    @media screen and (max-width: 760px) {
      position: relative;
      width: 106px;
      height: 58px;
      bottom: -5px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.damages-pet-photo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.pagination_images {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.pagination_button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--b-color-text-primary-2);
  background-color: var(--b-color-main-2);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin: 5px;
}

div.fullWidth {
  width: 100%;
}

.pagination_button.inactive {
  color: var(--b-color-main-2);
  background-color: var(--b-color-text-primary-2);
  border: 1px solid var(--b-color-main-2);
}

.sliderBtn {
  background-color: var(--b-color-main-2);
  border: 1px solid var(--b-color-main-2);
  height: 47px;
  width: 47px;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  box-shadow: 2px 2px 10px var(-b-color-main-2);

  &__disabled {
    background-color: var(--b-color-main-2);
    border: 1px solid var(--b-color-main-2);
    height: 47px;
    width: 47px;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    box-shadow: 2px 2px 10px var(-b-color-main-2);
    opacity: 0.6;
  }
}

.sliderBtnMobile {
  position: relative;
  height: 100px;
  // width: 30px;
  display: flex;

  &__disabled {
    @extend .sliderBtnMobile;
    opacity: 0.5;
  }
}

.Toastify__toast--success {
  border: 1px solid var(--b-color-bg-additional-4) !important;
  border-radius: 18px !important;
  background: var(--b-color-bg-main-3) !important;
}

.margin-0 {
  margin: 0 !important;
}

.policy_wrapper {
  margin: 5vh 0;

  @media screen and (max-width: 1200px) {
    width: 80%;
  }

  @media screen and (max-width: 600px) {
    width: 96%;
  }
}

.error-color {
  color: var(--b-color-main-2);
  font-family: var(--b-font-family-primary-1);
  font-weight: 600;
}

.policy-table-mobile {
  display: flex;
  flex-direction: column;
  gap: 7px;

  &__header {
    display: flex;
    height: 40px;
    justify-content: space-between;
    margin: 0 0 8px 0;
  }

  &__row {
    display: flex;
    flex-direction: row;
    // height: 38px;
    gap: 7px;
  }

  &__button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 15px;
    border-radius: 6px;
    padding: 10px 15px;
    font-size: var(--b-font-size-s);
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    word-break: break-word;
    transition: 0.3s ease-in;

    &>img {
      width: 24px;
      height: 24px;
    }
  }

  &__info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 15px;
    border-radius: 6px;
    padding: 3px 15px;
    font-size: var(--b-font-size-s);
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    word-break: break-word;
    transition: 0.3s ease-in;
    background-color: var(--b-color-additional-1);
    color: var(--b-color-bg-main-2);
  }

  &__left {
    width: 50%;
    border-radius: 6px 0px 0px 6px;
    background: var(--b-color-main-1);
    padding: 10px 15px;
    color: var(--b-color-text-primary-2);
    font-family: var(--b-font-family-primary-1);
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    word-break: break-word;
    display: flex;
    align-items: center;
    text-align: start;

    @media screen and (min-width: 600px) {
      font-size: 16px;
    }

    &.total {
      background-color: var(--b-color-bg-additional-6);
      color: var(--b-color-text-primary-1);
    }
  }

  &__right {
    width: 50%;
    border-radius: 0px 6px 6px 0px;
    background: var(--b-color-bg-main-3);
    padding: 10px 15px;
    color: var(--b-color-text-primary-1);
    font-family: var(--b-font-family-primary-1);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    font-variant-numeric: lining-nums proportional-nums;
    word-break: break-word;
    text-align: start;
    position: relative;
    transition: 0.3s ease;

    @media screen and (min-width: 600px) {
      font-size: 16px;
    }

    &.total-amount {
      background-color: var(--b-color-bg-additional-6);
      color: var(--b-color-text-primary-1);
    }

    &.total {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background-color: var(--b-color-bg-additional-6);
      color: var(--b-color-text-primary-1);
    }

    .reset-btn {
      position: absolute;
      right: -15px;
      transform: translateY(-50%);
      top: 50%;
      box-shadow: 2px 2px 6px 0px var(--b-shadow-main);
      border-radius: 50%;
    }
  }

  &__right-high {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 50%;
    text-align: start;
    transition: 0.3s ease;
  }

  &__pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 8px 0 0 0;
  }

  &__full-width {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px 15px !important;
    background-color: var(--b-color-bg-main-1);
    color: var(--b-color-text-primary-1);
    border-radius: 6px;
    text-align: start !important;
    font-weight: 400 !important;
    font-family: var(--b-font-family-primary-1);
    font-variant-numeric: lining-nums proportional-nums;

    ul {
      list-style-type: none;
      padding-left: 10px;
    }

    li {
      padding-left: 12px;
      background-image: var(--bgi-marker);
      background-position: 2px 7px;
      background-size: 6px;
      background-repeat: no-repeat;

      span {
        color: #52BB37;
      }
    }
  }
}

.blurred-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // backdrop-filter: blur(5px);
  background: var(--b-shadow-white);
  z-index: 9999;
}

.loading_back_red {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--b-color-main-2);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999999;

  @media screen and (max-width: 600px) {
    & img {
      width: 80%;
    }
  }
}

.watermark {
  position: absolute;
  top: 20px;
  left: 20px;
  padding: 5px;
  background-color: var(--b-color-additional-1);
  color: var(--b-color-text-primary-1);
  opacity: 0.7;
  border-radius: 5px;
  font-size: 20px;
  // z-index: 1;

  &__close {
    @extend .watermark;
    left: auto;
    right: 20px;
    padding: 2px 10px;
  }

  &__close_transparent {
    background-color: initial;
  }
}

.positioned-label {
  z-index: 1;
}

.previewer_buttons {
  position: absolute;
  opacity: 0.5;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 15;

  & img {
    height: 10vh;
  }

  &__left {
    @extend .previewer_buttons;
    left: 5%;
  }

  &__right {
    @extend .previewer_buttons;
    right: 5%;
  }
}

.preview-file {
  padding: 20px;
  background: var(--b-color-bg-main-1);
  border-radius: 25px;
  box-shadow: 10px 10px 10px var(--b-shadow-main);

  @media screen and (min-width: 600px) {
    padding: 35px;
    border-radius: 45px;

  }

  &__image {
    max-height: 67vh;
  }
}

.preview-btns {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin: 10px 0 0 0;
}

.translation-input {
  border: none;
  background-color: inherit;
  outline: none;
  height: 100%;
  width: 100%;
  text-align: center;
  padding: 0 1rem;
}

.attach_btn {
  position: fixed;
  z-index: 100;
  min-width: 200px;
  bottom: 15px;
  right: 10px;

  &__container {
    background-color: var(--b-color-bg-additional-4);
    border-radius: 18px;
    padding: 10px;

    &.opened {
      width: 25vw;

      @media screen and (max-width: 1200px) {
        width: 30vw;
      }

      @media screen and (orientation: landscape) and (max-width: 900px) {
        width: 40vw;
      }

      @media screen and (orientation: portrait) and (max-width: 900px) {
        width: 70vw;
      }

      @media screen and (orientation: portrait) and (max-width: 600px) {
        width: 95vw;
      }
    }
  }
}

.custom_container {
  width: 100%;
  padding: 0 5%;
  max-width: 1200px;
}

.claim__content {
  // width: 100%;
}

.dialog-content {
  max-height: 650px;

  @media screen and (min-width: 600px) {
    min-width: 500px;
  }
}

.group-title {
  width: 100%;
  padding: 30px 0 0 0;
  color: var(--b-color-text-primary-1);
  font-size: 24px;
  font-weight: 600;
  font-family: var(--b-font-family-primary-1);
  font-variant-numeric: lining-nums proportional-nums;

  @media screen and (min-width: 600px) {
    font-size: 30px;
  }
}

.multi-question-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;
  margin: 180px 0 0 0;

  @media (min-width: 1000px) {
    flex-direction: row;
    align-items: flex-start;
    gap: 50px;

    // &>* {
    //   flex-grow: 1;
    // }

    // .pet-image {
    //   flex-grow: 0;
    // }
  }

}

.panda-loader {
  width: 99vw;
}

.red-circle {
  cursor: pointer;
  background: var(--b-color-main-2);
  width: 47px;
  height: 47px;
  padding: 5px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 2px 10px var(-b-color-main-2);
}

.invoice-table-title {
  margin: 40px 0 0 0;

  &__title {
    color: var(--b-color-main-1);
    @extend .headline1;
    text-align: center;

    @media screen and (max-width: 1200px) {
      font-size: 32px;
    }

    @media screen and (max-width: 760px) {
      font-size: 24px;
    }
  }

  &__name {
    color: var(--b-color-text-primary-1);
    @extend .headline2;

    @media screen and (max-width: 1200px) {
      font-size: 26px;
    }

    @media screen and (max-width: 760px) {
      font-size: 20px;
    }
  }
}

.invoice-table-message {
  margin: 5vh 0 0 0;
  padding: 0 6px;
  color: var(--b-color-text-primary-1);
  font-family: var(--b-font-family-primary-1);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  font-size: 14px;
  text-align: center;

  @media screen and (min-width: 760px) {
    font-size: 16px;
    // margin: 10vh 0 0 0;
  }

  @media screen and (min-width: 1200px) {
    font-size: var(--b-font-size-xl);
    text-align: start;
  }
}

.invoice-table {
  width: 100%;
  margin: 40px 0;
}

.invoice-table-info {
  align-self: center;
  position: relative;
  min-height: 60px;
  display: flex;
  align-items: center;
  margin: 0 0 5vh 0;
  padding: 0 25px 0 40px;
  border-radius: 13px;
  background: var(--b-color-main-1);
  color: var(--b-color-bg-main-2);
  text-align: center;
  font-family: var(--b-font-family-primary-1);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  box-shadow: 0px 0px 10px var(--b-shadow-light);

  &>div {
    width: 58px;
    height: 58px;
    border-radius: 50%;
    background: var(--b-color-main-1);
    position: absolute;
    left: 0;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 10px var(--b-shadow-light);
    top: -10px;
  }

  &>span {
    text-align: start;
  }

  &.mobile {
    min-height: 78px;
    padding: 15px 15px 15px 20px;
    font-size: 14px;

    &>div {
      width: 40px;
      height: 40px;
      top: 0;
      transform: translate(-35%, -35%);

      &>img {
        height: 80%;
      }
    }
  }
}

.invoice-table-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 65px;
  margin: 10px 0 80px 0;

  @media screen and (max-width: 760px) {
    gap: 38px;
    margin: 0 0 20px 0;
  }

  @media screen and (max-width: 360px) {
    gap: 15px;
  }
}

.invoice-modal-buttons {
  @extend .invoice-table-buttons;
  margin: 5vh auto 0;

  @media screen and (max-width: 760px) {
    flex-direction: column;
    align-items: center;
    gap: 8px;
    margin-top: 1vh;
  }
}

.column-centered {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tile-table-wrapper {
  margin: 5vh 0;
}

.tile-table-wrapper-modal {
  margin: 2.5vh 0;
}

.tile-edit-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 7px 7px;

  &__tile {
    padding: 5px;
    border-radius: 6px;
    background: var(--b-color-additional-1);
    color: var(--b-color-bg-main-2);
    font-family: var(--b-font-family-primary-1);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    font-variant-numeric: lining-nums proportional-nums;
    word-break: break-word;

    @media screen and (min-width: 600px) {
      font-size: 16px;
      padding: 10px 15px;
      max-width: unset;
    }
  }

  &__tile-header th:first-child {
    min-width: 40px;
  }

  &__tile-header {
    @extend .tile-edit-table__tile;
    background: var(--b-color-main-1);
    color: var(--b-color-text-primary-2);
  }

  &__tile-row td {
    min-width: 40px;
  }

  &__tile-row td:not(:first-child) {
    @extend .tile-edit-table__tile;
  }

  &__tile-row {
    &.selected td:not(:first-child) {

      @extend .tile-edit-table__tile;
      background-color: var(--b-color-bg-additional-4);
      color: var(--b-color-bg-additional-3);
    }
  }

  &__tile-row {
    &.result td {
      @extend .tile-edit-table__tile;
      background-color: var(--b-color-bg-main-3);
      color: var(--b-color-text-primary-1);
    }
  }
}

.bg-white1 {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 15px;
    background-color: var(--b-color-text-primary-2);
  }
}


.flow-final-step {
  margin: 10vh 0;

  @media screen and (min-width: 600px) {
    margin: 15vh 0 10vh 0;
  }

  div.show-final-body {
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    justify-content: center;
  }

  button {
    width: fit-content;
  }
}

.admin-modal__email {
  input {
    background: var(--b-color-text-primary-2);
  }
}

.no-background {
  background: unset;
}

.loading_container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  // background-color: rgba(255, 0, 0, 0.384);
  z-index: 1000;
  border-radius: 44px;
}