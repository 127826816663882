.insurance {
    background-color: var(--b-color-text-primary-2);

    @media (min-width: 768px) {
        min-height: 600px;
    }

    &__container {
        margin: 0 auto;
        max-width: 1140px;
        padding: 0 15px;

        @media (min-width: 1020px) {
            padding: 0 96px;
        }

        @media (min-width: 800px) {
            padding: 0 15px;
        }

        @media (min-width: 750px) {
            padding: 0 150px;
        }

        @media (min-width: 600px) {
            padding: 0 96px;
        }

        @media (min-width: 500px) {
            padding: 0 48px;
        }
    }

    &>* {
        padding-top: 4.5em;
        padding-bottom: 4.5em;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        padding-bottom: 4.5rem;

        @media screen and (min-width: 800px) {
            padding-left: 48px;
            padding-right: 48px;
        }

        &__title {
            font-size: 20px;
            font-weight: 700;
            line-height: 24px;
        }

        &__phone {
            font-weight: 700;
            margin-left: 5px;

            @media screen and (max-width: 800px) {
                margin-top: 10px;
            }
        }

        &__right {
            display: flex;
            font-size: 16px;
            line-height: 16px;

            @media screen and (max-width: 800px) {
                display: inline-block;
            }
        }
    }

    &__body {
        &__header {
            align-items: flex-end;
            display: flex;
            justify-content: space-between;
            width: 100%;
        }

        &__title {
            background-color: var(--b-color-brand-primary-shaded);
            color: var(--b-color-brand-primary);
            font-size: 20px;
            font-weight: 700;
            line-height: 24px;
            max-width: 70%;
            min-width: 40%;
            padding: 20px 48px 0;
            transform: translateY(20%);
            width: auto;
            z-index: 1;

            @media screen and (max-width: 800px) {
                padding: 0;
            }
        }

        &__sub-title {
            display: flex;
            font-size: 16px;
            font-weight: 400;
            line-height: 16px;
            margin-top: 5px;
        }

        &__status {
            border-radius: 25px;
            color: var(--b-color-text-primary-2);
            display: inline-block;
            font-size: 16px;
            font-weight: 700;
            height: 35px;
            line-height: 34px;
            margin-right: 70px;
            padding: 0 25px;
            transform: translateY(50%);
            z-index: 1;
            background: rgb(55, 185, 53);

            @media screen and (max-width: 800px) {
                margin-right: 0;
            }
        }

        &__summary {
            display: flex;
            justify-content: space-between;
            min-height: 210px;
            padding-bottom: 40px;
            padding-top: 56px;
            position: relative;
            align-items: center;
        }
    }

    &__summary {
        &__head {
            flex-direction: column;
            margin-bottom: -12px;
        }

        &__details {
            align-self: baseline;
            display: flex;
            justify-content: space-between;
            width: 100%;
        }

        &__content {
            width: 57%;

            @media screen and (max-width: 800px) {
                width: 100%;
            }
        }

        &__card {
            background-color: var(--b-color-text-primary-2);
            display: flex;
        }

        &__wrapper {
            background-color: var(--b-color-brand-primary-shaded);
            display: flex;
            flex-direction: column;
            margin-bottom: 25px;
            padding: 0 48px;
            position: relative;

            @media screen and (max-width: 800px) {
                padding: 0;
            }
        }

        &__icon {
            align-items: center;
            display: flex;
            height: 80px;

            @media screen and (max-width: 800px) {
                display: none;
            }
        }
    }

    &__card {
        &__elem {
            @media (min-width: 800px) {
                overflow: hidden;
            }

            padding: 10px;
            width: 100%;
        }

        &__column {
            background-color: var(--b-color-ui-1);
            border-color: var(--b-color-brand-primary-shaded);
            border-bottom-style: solid;
            border-bottom-width: 2px;
            display: flex;
            height: auto;
            justify-content: space-between;
            line-height: 40px;
            min-height: 40px;
            padding-left: 10px;
            padding-right: 10px;

            @media (min-width: 800px) {
                overflow: auto;
            }
        }

        &__wrapper {
            background-color: var(--b-color-text-primary-2);
            display: flex;

            @media screen and (max-width: 800px) {
                flex-direction: column;
                width: 100%;
            }
        }

        &__box {
            width: 100%;
            padding: 10px;

            @media screen and (max-width: 800px) {
                padding: 0;
            }
        }

        &__item {
            border-bottom-style: solid;
            border-bottom-width: 2px;
            display: flex;
            height: auto;
            justify-content: space-between;
            line-height: 40px;
            min-height: 40px;
            padding-left: 10px;
            padding-right: 10px;
            flex-wrap: wrap;

            @media (min-width: 800px) {
                overflow: auto;
            }

            border-color: var(--b-color-brand-primary-shaded);
        }

        &__label {
            color: var(--b-color-text-primary);
            margin-top: 0.5rem;

            @media (min-width: 800px) {
                padding-right: 10px;
            }
        }

        &__value {
            font-weight: 700;
            margin-top: 0.5rem;
            max-width: 55%;
            min-width: 15%;
            text-align: right;
            word-break: break-word;
            color: var(--b-color-brand-primary);
        }

        &__cart {
            color: var(--b-color-text-highlight);
            cursor: pointer;
            display: flex;
            justify-content: flex-end;
        }

        &__btn {
            border: 1px solid var(--b-color-text-highlight);
            border-radius: 6px;
            color: var(--b-color-text-highlight);
            display: flex;
            margin-top: 1rem;
            padding: 10px 12px;
            width: -webkit-fit-content;
            width: -moz-fit-content;
            width: fit-content;

            &:hover {
                color: var(--b-color-text-highlight);
                opacity: 0.7;
                text-decoration: none;
            }
        }
    }

    &__description {
        color: var(--b-color-text-primary);
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 24px;
        max-width: 480px;
    }

    &__icon {
        &__wrapper {
            color: var(--b-color-support-info);
            margin-top: 10px;
            position: relative;

            &:last-child {
                margin-right: 24px;
            }
        }
    }

    &__details {
        &__title {
            color: var(--b-color-brand-primary);
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            padding-bottom: 32px;
        }

        &__desc {
            align-self: flex-end;
            border: 2px solid;
            border-radius: 10px;
            color: var(--b-color-brand-primary);
            font-weight: 700;
            margin-bottom: 22px;
            margin-top: -12px;
            padding: 5px 10px;
        }
    }

    &__cart {
        &__title {
            color: var(--b-color-text-highlight);
            font-weight: 700;
        }
    }

    &__document {
        display: flex;
        flex-direction: column;
        margin-bottom: 44px;

        &__title {
            color: var(--b-color-brand-primary);
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            margin-bottom: 24px;
            padding-top: 20px;
        }

        &__link {
            background-position: 0;
            background-repeat: no-repeat;
            background-size: 18px 23px;
            color: var(--b-color-brand-primary);
            cursor: pointer;
            display: flex;
            font-size: 14px;
            line-height: 18px;
            margin-bottom: 20px;

            a {
                color: var(--b-color-brand-primary);

                &:hover {
                    color: #0056b3;
                }
            }
        }

        &__icon {
            color: var(--b-color-support-info);
            display: flex;
            margin-right: 10px;
            margin-top: -4px;
            width: 24px;
        }
    }

    &__more {
        color: var(--b-color-brand-primary);
        display: flex;
        justify-content: flex-end;
        margin-bottom: 100px;

        &__btn {
            border: 1px solid var(--b-color-support-info);
            border-radius: 8px;
            color: var(--b-color-support-info);
            cursor: pointer;
            display: flex;
            padding: 8px 16px;
        }

        &__arrow {
            color: var(--b-color-support-info);
            line-height: 24px;
            margin-left: 8px;

            &.reverse {
                transform: rotate(180deg);
            }
        }
    }

    &__info {
        align-items: center;
        background-color: var(--b-color-brand-primary-shaded);
        display: flex;
        flex-direction: row;
        margin-bottom: 32px;
        padding: 24px 32px;

        @media screen and (max-width: 800px) {
            padding: 0;
        }

        &__img {
            color: var(--b-color-support-info);
            margin-right: 16px;
        }

        &__subject {
            color: var(--b-color-support-info);
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
        }

        &__btn {
            background-color: var(--b-color-support-info);
            border: 1px solid transparent;
            color: var(--b-color-text-primary-2);
            font-size: 14px;
            padding: 5px 16px;
        }
    }
}

.insurances_container {
    z-index: 2;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__left_active {
        width: 100%;
        // background-image: var(--bgi-pet-tab-left);
        background-size: 100% 250px;
        background-repeat: no-repeat;
        overflow-x: hidden;
        overflow-y: hidden;
        margin: 0 0 -30vh 0;
        padding: 0 0 30vh 0;

        @media screen and (max-width: 1200px) {
            background-size: 100% 200px;
        }

        @media screen and (max-width: 900px) {
            background-size: 100% 150px;
        }

        @media screen and (max-width: 600px) and (orientation: portrait) {
            background-size: 100% 120px;
        }
    }

    &__left_active:after {
        content: "";
        position: absolute;
        top: 250px;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--b-color-bg-main-1);
        z-index: -2;

        @media screen and (max-width: 1200px) {
            top: 200px;
        }

        @media screen and (max-width: 900px) {
            top: 150px;
        }

        @media screen and (max-width: 600px) and (orientation: portrait) {
            top: 120px;
        }
    }

    // &__left_active.mobile {
    //     background-image: var(--bgi-pet-tab-left-mobile);
    //     background-size: cover;
    // }

    &__right_active {
        width: 100%;
        background-image: var(--bgi-pet-tab-right);
        background-size: cover;
        background-repeat: no-repeat;
        margin: 0 0 -30vh 0;
        padding: 0 0 30vh 0;
    }

    &__wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 220px 0 0 0;

        @media screen and (max-width: 900px) {
            padding: 200px 0 0 0;
        }

        @media screen and (max-width: 600px) and (orientation: portrait) {
            padding: 150px 0 0 0;
        }
    }

    &__bookmark_active {
        position: absolute;
        top: 75px;
        left: 33vw;
        width: 200px;
        color: var(--b-color-main-1);
        text-align: center;
        font-size: 24px;
        font-family: var(--b-font-family-primary-1);
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        cursor: pointer;

        @media screen and (max-width: 1200px) {
            top: 130px;
            left: 17vw;
        }

        @media screen and (max-width: 900px) {
            top: 94px;
            left: 15vw;
            width: 150px;
            font-size: 12px;
        }

        @media screen and (max-width: 600px) {
            top: 48px;
            left: 13vw;
            width: 95px;
            // font-size: 12px;
        }

        @media screen and (max-width: 380px) {
            top: 44px;
            left: 10vw;
        }
    }

    &__bookmark_active__desktop {
        position: absolute;
        top: 75px;
        left: 32vw;
        width: 150px;
        color: var(--b-color-main-1);
        text-align: center;
        font-family: var(--b-font-family-primary-1);
        font-size: 21px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        cursor: pointer;

        @media screen and (max-width: 1200px) {
            top: 75px;
            left: 50%;
            transform: translateX(-21vw);
            width: 200px;
            font-size: 20px;
        }

        @media screen and (max-width: 900px) {
            top: 75px;
            left: 50%;
            transform: translateX(-21vw);
            width: 150px;
            font-size: 18px;
        }

        @media screen and (max-width: 600px) {
            top: 55px;
            left: 50%;
            transform: translateX(-21vw);
            width: 120px;
            font-size: 14px;
        }

        @media screen and (max-width: 380px) {
            top: 44px;
            left: 13vw;
        }
    }

    &__bookmark_passive {
        position: absolute;
        top: 75px;
        right: 33vw;
        width: 150px;
        color: var(--b-color-bg-additional-2);
        text-align: center;
        font-family: var(--b-font-family-primary-1);
        font-size: 21px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        cursor: pointer;
    }

    &__header {
        color: var(--b-color-text-primary-1);
        font-family: var(--b-font-family-primary-1);
        font-size: 34px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin: 5vh 0 0 0;

        @media screen and (max-width: 1200px) {
            font-size: 28px;
        }

        @media screen and (max-width: 600px) {
            font-size: 20px;
            margin: 0;
        }
    }

    &__product {
        position: absolute;

        &>div {
            position: relative;
            top: -20vh;
            left: 34vw;
        }

        &.mobile {
            &>div {
                top: -8vh;
            }
        }
    }

    &__status_active {
        display: flex;
        padding: 9px 34px;
        align-items: flex-start;
        justify-content: center;
        border-radius: 13px;
        background: var(--b-color-main-1);
        position: absolute;
        top: 30vh;
        right: 10vw;
        color: var(--b-color-text-primary-2);
        leading-trim: both;
        text-edge: cap;
        font-family: var(--b-font-family-primary-1);
        font-size: 21px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;

        @media screen and (max-width: 900px) {
            top: 20vh;
            font-size: 18px;
        }

        @media screen and (max-width: 600px) {
            top: 20vh;
            font-size: 16px;
        }

        &.mobile {
            position: relative;
            display: flex;
            align-items: center;
            height: 30px;
            top: -1vh;
            right: auto;
            font-size: 16px;
            border-radius: 15px;
            padding: 9px 20px;
            margin: 2.5vh 0 0 0;
        }
    }

    &__status_inactive {
        @extend .insurances_container__status_active;
        background-color: var(--b-color-additional-1);
    }

    &__toDoBtn {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 5vh 0 0 0;

        &>img {
            position: relative;
            width: 170px;
            height: 96px;
            margin: 0 0 -20px 0;
            cursor: pointer;

            @media screen and (max-width: 600px) {
                width: 125px;
                height: 70px;
                margin: 0 0 -10px 0;
            }
        }

        &.mobile {
            margin: 0 0 2.5vh 0;

            &>img {
                width: 170px;
                height: 96px;
                margin: 0 0 -20px 0;

                @media screen and (max-width: 900px) and (orientation: landscape) {
                    width: 125px;
                    height: 70px;
                    margin: 0 0 -10px 0;
                }

                @media screen and (max-width: 600px) and (orientation: portrait) {
                    width: 125px;
                    height: 70px;
                    margin: 0 0 -10px 0;
                }
            }
        }
    }
}